import React, { useEffect } from "react";
import { render } from "react-dom";
import Highcharts from "highcharts";
import HighchartsReact from "highcharts-react-official";
import highchartsMap from "highcharts/modules/map";
import proj4 from "proj4";
import mapDataIE from "@highcharts/map-collection/countries/ar/ar-all.geo.json";

const HighChartMap = (props) => {
    highchartsMap(Highcharts); // Initialize the map module

    if (typeof window !== "undefined") {
        window.proj4 = window.proj4 || proj4;
    }

    const provincias = [
        { key: "ar-tf", value: 0 },
        { key: "ar-ba", value: 0 },
        { key: "ar-sj", value: 0 },
        { key: "ar-mz", value: 0 },
        { key: "ar-nq", value: 0 },
        { key: "ar-rn", value: 0 },
        { key: "ar-lp", value: 0 },
        { key: "ar-ct", value: 0 },
        { key: "ar-sl", value: 0 },
        { key: "ar-cb", value: 0 },
        { key: "ar-lr", value: 0 },
        { key: "ar-sa", value: 0 },
        { key: "ar-se", value: 0 },
        { key: "ar-tm", value: 0 },
        { key: "ar-cc", value: 0 },
        { key: "ar-fm", value: 0 },
        { key: "ar-cn", value: 0 },
        { key: "ar-er", value: 0 },
        { key: "ar-ch", value: 0 },
        { key: "ar-sf", value: 0 },
        { key: "ar-mn", value: 0 },
        { key: "ar-df", value: 0 },
        { key: "ar-sc", value: 0 },
        { key: "ar-jy", value: 0 },
    ];
    const apiData = props.data.data;

    var dataValues = provincias.map((val) => {
        let newValue = apiData.filter((v) => v.key === val.key);

        let value = val.value;
        if (newValue.length) {
            value = newValue[0].value;
        }
        return [val.key, value];
    });

    const mapOptions = {
        chart: {
            height: 840,
            map: "countries/ar/ar-all",
            events: {
                /*   load: function() {
                    this.mapZoom(0.7);
                },*/
            },
        },

        title: {
            text: props.title,
        },

        subtitle: {
            text: "Argentina",
        },

        mapNavigation: {
            enabled: true,
            buttonOptions: {
                verticalAlign: "bottom",
            },
        },

        colorAxis: {
            min: 0,
            stops: [
                [0.4, "#ff6f1f"],
                [0.65, "#f60000"],
                [1, "#ba0000"],
            ],
        },

        series: [
            {
                data: dataValues,
                name: "Provincia",
                states: {
                    hover: {
                        color: "#920200",
                    },
                },
                mapData: mapDataIE,
                dataLabels: {
                    enabled: false,
                    format: "{point.name}",
                },
            },
        ],
    };

    useEffect(() => {
        // fix chart width
        window.dispatchEvent(new Event("resize"));
    }, []);
    return (
        <div style={{ height: 800 }}>
            <HighchartsReact constructorType={"mapChart"} highcharts={Highcharts} options={mapOptions} />
        </div>
    );
};

export default HighChartMap;
