import Card from "@material-ui/core/Card";
import Typography from "@material-ui/core/Typography";
import { makeStyles } from "@material-ui/core/styles";
import React from "react";
import config from "../config";
import { AutocompleteInput, DateInput, EditActions, useEditController, Link, ReferenceInput, regex, ImageInput, ImageField, required, NumberInput, SimpleForm, TextInput, Title, minLength } from "react-admin"; // eslint-disable-line import/no-unresolved

const useEditStyles = makeStyles({
    actions: {
        float: "right",
    },
    card: {
        marginTop: "1em",
        maxWidth: "30em",
    },
});

const validateUrl = regex(/^(?:http(s)?:\/\/)[\w.-]+(?:\.[\w\.-]+)+[\w\-\._~:/?#[\]@!\$&'\(\)\*\+,;=.]+$/, "Must be a valid URL");

const CommentEdit = (props) => {
    const classes = useEditStyles();
    const { resource, record, redirect, save, basePath, version } = useEditController(props);
    if (record && record.image.length && record.image[0].url.indexOf("http") === -1) {
        record.image[0].url = config.API_URL + record.image[0].url;
    }
    return (
        <div className="edit-page">
            <Title defaultTitle={`Banner #${record ? record.id : ""}`} />
            <div className={classes.actions}>
                <EditActions basePath={basePath} resource={resource} data={record} hasShow hasList />
            </div>
            <Card className={classes.card}>
                {record && (
                    <SimpleForm basePath={basePath} redirect={redirect} resource={resource} record={record} save={save} version={version}>
                        <TextInput disabled source="id" fullWidth />
                        <TextInput source="title" validate={[required(), minLength(10)]} fullWidth />
                        <DateInput source="created_at" />
                        <TextInput source="description" fullWidth={true} multiline={true} />
                        <ImageInput source="image" label="Image" accept="image/*">
                            <ImageField source="url" title="title" />
                        </ImageInput>
                        <TextInput source="url" fullWidth validate={validateUrl} />
                        <NumberInput source="order" step={1} defaultValue={0} />
                    </SimpleForm>
                )}
            </Card>
        </div>
    );
};

export default CommentEdit;
