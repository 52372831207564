/* eslint react/jsx-key: off */
import PeopleIcon from "@material-ui/icons/People";
import memoize from "lodash/memoize";
import { useMediaQuery } from "@material-ui/core";
import React from "react";
import { BulkDeleteWithConfirmButton, Datagrid, BooleanInput, BooleanField, Filter, List, SearchInput, SimpleList, TextField, TextInput } from "react-admin";

import UserEditEmbedded from "./UserEditEmbedded";
export const UserIcon = PeopleIcon;

const UserFilter = ({ permissions, ...props }) => (
    <Filter {...props}>
        <SearchInput source="q" alwaysOn />
        <TextInput source="username" />
        <TextInput source="last_name" />
        <TextInput source="email" />
        <BooleanInput source="confirmed" />
        <BooleanInput source="blocked" />
    </Filter>
);

const UserBulkActionButtons = (props) => <BulkDeleteWithConfirmButton {...props} />;

const rowClick = memoize((permissions) => (id, basePath, record) => {
    return Promise.resolve("edit");
});

const UserList = ({ permissions, ...props }) => (
    <List {...props} filters={<UserFilter permissions={permissions} />} sort={{ field: "name", order: "ASC" }} bulkActionButtons={<UserBulkActionButtons />}>
        {useMediaQuery((theme) => theme.breakpoints.down("sm")) ? (
            <SimpleList primaryText={(record) => record.name} />
        ) : (
            <Datagrid rowClick={rowClick(permissions)} expand={<UserEditEmbedded />} optimized>
                <TextField source="id" />
                <TextField source="name" />
                <TextField source="last_name" />
                <TextField source="username" />
                <TextField source="email" />
                <BooleanField source="confirmed" />
                <BooleanField source="blocked" />
            </Datagrid>
        )}
    </List>
);

export default UserList;
