import React from "react";
import Highcharts from "highcharts";
import HighchartsReact from "highcharts-react-official";

const HighChartBars = (props) => {
    const options = {
        chart: {
            type: "column",
        },
        title: {
            text: props.title,
        },
        xAxis: {
            title: {
                text: props.x,
            },
            categories: props.data.data.map((v) => v.key),
        },
        yAxis: {
            min: 0,
            title: {
                text: props.y,
            },
            stackLabels: {
                enabled: true,
                style: {
                    fontWeight: "bold",
                    color:
                        // theme
                        (Highcharts.defaultOptions.title.style && Highcharts.defaultOptions.title.style.color) || "gray",
                },
            },
        },
        legend: {
            align: "right",
            x: -30,
            verticalAlign: "top",
            y: 25,
            floating: true,
            backgroundColor: Highcharts.defaultOptions.legend.backgroundColor || "white",
            borderColor: "#CCC",
            borderWidth: 1,
            shadow: false,
        },
        tooltip: {
            headerFormat: "<b>De {point.x} años</b><br/>",
            pointFormat: "{series.name}: {point.y}%",
        },
        plotOptions: {
            series: Array.isArray(props.color)
                ? {
                      colorByPoint: true,
                      colors: props.color,
                  }
                : {
                      color: props.color,
                  },
        },
        series: [
            {
                name: props.serie,
                data: props.data.data.map((v) => v.value),
            },
        ],
    };

    return (
        <div>
            <HighchartsReact highcharts={Highcharts} options={options} />
        </div>
    );
};

export default HighChartBars;
