import React, { FC, ComponentType } from "react";
import Card from "@material-ui/core/Card";
import { makeStyles } from "@material-ui/core/styles";
import { SvgIconProps } from "@material-ui/core/SvgIcon";

interface Props {
    bgColor: string;
    Icon: ComponentType<SvgIconProps>;
    iconColor: string;
}

const CardIcon: FC<Props> = ({ Icon, bgColor, iconColor }) => {
    const useStyles = makeStyles({
        card: {
            float: "left",
            margin: "3px 15px 0 0",
            zIndex: 100,
            borderRadius: 3,
        },
        icon: {
            float: "right",
            width: 50,
            height: 50,
            padding: 14,
            color: iconColor,
        },
    });
    const classes = useStyles();
    return (
        <Card className={classes.card} style={{ backgroundColor: bgColor }}>
            <Icon className={classes.icon} />
        </Card>
    );
};

export default CardIcon;
