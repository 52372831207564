import React, { useState, useEffect, useCallback, FC, CSSProperties } from "react";
import Api from "../services/api";
import { useMediaQuery, Theme } from "@material-ui/core";

import KpisCard from "./KpisCard";
import HighChart from "./highcharts/highchart-line";
import HighChartBars from "./highcharts/highchart-bars";
import HighChartPie from "./highcharts/highchart-pie";
import HighChartMap from "./highcharts/highchart-map";

import dataLine2 from "../data/chartline2.json";

import dataBars2 from "../data/bars2.json";

import dataMap1 from "../data/map1.json";

import dataPie1 from "../data/pie1.json";

import dataKpi from "../data/kpi.json";

import { Customer, Order, Review } from "../types";
import TimelineOutlined from "@material-ui/icons/TimelineOutlined";
import WidgetsOutlinedIcon from "@material-ui/icons/WidgetsOutlined";
import TrendingUpOutlinedIcon from "@material-ui/icons/TrendingUpOutlined";
import WifiOutlinedIcon from "@material-ui/icons/WifiOutlined";
import PeopleAltOutlinedIcon from "@material-ui/icons/PeopleAltOutlined";

interface OrderStats {
    revenue: number;
    nbNewOrders: number;
    pendingOrders: Order[];
}

interface CustomerData {
    [key: string]: Customer;
}

interface State {
    nbNewOrders?: number;
    pendingOrders?: Order[];
    pendingOrdersCustomers?: CustomerData;
    pendingReviewsCustomers?: CustomerData;
    revenue?: number;
}

const styles = {
    flex: { display: "flex" },
    flexColumn: { display: "flex", flexDirection: "column" },
    leftCol: { flex: 1, marginRight: "1em" },
    rightCol: { flex: 1, marginLeft: "1em" },
    singleCol: { marginTop: "2em", marginBottom: "2em" },
    mt50: { marginTop: "50px" },
};

const Dashboard: FC = () => {
    const [state, setState] = useState({} as State);

    const [kpiData, setKpiData] = useState(null);
    const [ageData, setAgeData] = useState(null);
    const [newData, setNewData] = useState(null);
    const [hourData, setHourData] = useState(null);
    const [usuariosMapaData, setUsuariosMapaData] = useState(null);

    const isXSmall = useMediaQuery((theme: Theme) => theme.breakpoints.down("xs"));
    const isSmall = useMediaQuery((theme: Theme) => theme.breakpoints.down("sm"));

    async function datosGraficoEdad() {
        const res = new Api().get("/chart/getUsersByAgeRange");
        res.then((data) => setAgeData(data)).catch((err) => console.log(err));
    }

    var today = new Date();
    var dd = today.getDate();
    var mm = today.getMonth() + 1;
    var yyyy = today.getFullYear();

    async function datosGraficoNuevosClientes() {
        const res = new Api().get("/chart/getNewUsers?weeks=10&startingDate=" + yyyy + "/" + mm + "/" + dd);
        res.then((data) => setNewData(data)).catch((err) => console.log(err));
    }
    async function datosGraficoMapaUsuarios() {
        const res = new Api().get("/chart/getUsersByRegion");
        res.then((data) => setUsuariosMapaData(data)).catch((err) => console.log(err));
    }

    async function datosGraficoTransaccionesHora() {
        const res = new Api().get("/chart/getTransactionByHourInDateRange");
        res.then((data) => setHourData(data)).catch((err) => console.log(err));
    }
    async function datosKpi() {
        const res = new Api().get("/kpi/stats");
        res.then((data) => setKpiData(data)).catch((err) => console.log(err));
    }

    useEffect(() => {
        datosKpi();
        datosGraficoEdad();
        datosGraficoNuevosClientes();
        datosGraficoTransaccionesHora();
        datosGraficoMapaUsuarios();
    }, []);

    return (
        <div>
            <div style={styles.flex}>
                <div className="container-graficos">
                    {kpiData && kpiData.data && (
                        <div className="kpis">
                            <KpisCard icon={TimelineOutlined} color="#66d2b0" value={`$${kpiData.data[0].average.cashin}`} title={"pos.dashboard.cash_flow"} subtitle={"pos.dashboard.transfer"} />
                            <KpisCard icon={TimelineOutlined} color="#66d2b0" value={`$${kpiData.data[0].average.cashout}`} title={"pos.dashboard.cash_flow2"} subtitle={"pos.dashboard.transfer"} />

                            <KpisCard icon={TrendingUpOutlinedIcon} color="#bad313" value={kpiData.data[1].cantidad.cashin} title={"pos.dashboard.max_flow"} subtitle={"pos.dashboard.transfer"} />
                            <KpisCard icon={TrendingUpOutlinedIcon} color="#bad313" value={kpiData.data[1].cantidad.cashout} title={"pos.dashboard.max_flow2"} subtitle={"pos.dashboard.transfer"} />

                            <KpisCard icon={WidgetsOutlinedIcon} color="#ffc21e" value={`$${kpiData.data[2].balance}`} title={"pos.dashboard.net_balance"} />

                            <KpisCard icon={WifiOutlinedIcon} color="#ff6f1f" value={kpiData.data[2].connected_users} title={"pos.dashboard.connected_users"} />

                            <KpisCard icon={PeopleAltOutlinedIcon} color="#f60000" value={kpiData.data[2].active_customers} title={"pos.dashboard.total_users"} />
                        </div>
                    )}
                </div>
            </div>
            <div style={styles.mt50} />

            <div style={styles.flex} className="border-chart">
                <h2>Tablero de Clientes</h2>
                <div className="chart-container">
                    <div className="chart">
                        {newData && newData.data && <HighChart color="#ff6f1f" title="Evolución de usuarios últimos 3 meses" data={newData} x="Semanas" y="Usuarios" serie="Usuarios Nuevos" />}
                        <div className="mb40"></div>
                        {ageData && ageData.data && <HighChartBars color={["#66d2b0", "#bad313", "#ffc21e", "#f60000", "#ba0000"]} title="Usuarios por edad" x="Rango de edades" y="Porcentaje" serie="Porcentaje de Usuarios" data={ageData} />}
                    </div>
                    <div className="chart">{usuariosMapaData && usuariosMapaData.data && <HighChartMap data={usuariosMapaData} title="Usuarios por región" />}</div>
                </div>
            </div>
            <div style={styles.mt50} />

            <div style={styles.flex} className="border-chart large">
                <h2>Tablero de Transacciones</h2>
                <div className="chart-container">
                    <div className="chart">
                        {hourData && hourData.data && <HighChart color="#ff6f1f" title="Evolución de transacciones por hora" data={hourData} x="Transacciones" y="Horas" serie="Transacciones Nuevas" />}
                        <div className="mb40"></div>
                        <HighChartPie data={dataPie1} color={["#66d2b0", "#bad313", "#ffc21e", "#f60000", "#ba0000"]} title="Transacciones por tipo" serie="Porcentaje" />
                    </div>
                    <div className="chart">
                        <HighChartMap data={dataMap1} title="Transacciones por región" />
                    </div>
                </div>
            </div>
        </div>
    );
};

export default Dashboard;
