import ChatBubbleIcon from "@material-ui/icons/ChatBubble";
import CommentCreate from "./BannersCreate";
import CommentEdit from "./BannersEdit";
import CommentList from "./BannersList";
import CommentShow from "./BannersShow";
import { ShowGuesser } from "react-admin";

export default {
    list: CommentList,
    create: CommentCreate,
    edit: CommentEdit,
    show: CommentShow,
    icon: ChatBubbleIcon,
};
