import React from "react";

import { Create, DateInput, TextInput, SimpleForm, required, NumberInput, regex, minLength, ImageInput, ImageField } from "react-admin"; // eslint-disable-line import/no-unresolved
import PostReferenceInput from "./PostReferenceInput";

const now = new Date();
const defaultSort = { field: "title", order: "ASC" };

const validateUrl = regex(/^(?:http(s)?:\/\/)[\w.-]+(?:\.[\w\.-]+)+[\w\-\._~:/?#[\]@!\$&'\(\)\*\+,;=.]+$/, "Must be a valid URL");

const CommentCreate = (props) => (
    <Create {...props}>
        <SimpleForm redirect={false}>
            <TextInput source="title" validate={[required(), minLength(10)]} fullWidth />
            <DateInput source="created_at" defaultValue={now} />
            <TextInput source="description" fullWidth={true} multiline={true} />
            <ImageInput source="image" label="Image" accept="image/*">
                <ImageField source="src" title="title" />
            </ImageInput>
            <TextInput source="url" fullWidth validate={validateUrl} />
            <NumberInput source="order" step={1} defaultValue={0} />
        </SimpleForm>
    </Create>
);

export default CommentCreate;
