import { createMuiTheme } from "@material-ui/core/styles";
import indigo from "@material-ui/core/colors/indigo";
import pink from "@material-ui/core/colors/pink";
import red from "@material-ui/core/colors/red";

/*export const bitYouTheme = {
    palette: {
        primary: {
            light: "#5f5fc4",
            main: "#333333",
            dark: "#001064",
            contrastText: "#fff"
        },
        secondary: {
            light: "#5f5fc4",
            main: "#ff6f1f",
            dark: "#f60000",
            contrastText: "#fff"
        }
    },
    overrides: {
        MuiFilledInput: {
            root: {
                backgroundColor: "rgba(0, 0, 0, 0.04)",
                "&$disabled": {
                    backgroundColor: "rgba(0, 0, 0, 0.04)"
                }
            }
        }
    }
};*/
export const bitYouTheme = createMuiTheme({
    palette: {
        primary: {
            light: "#5f5fc4",
            main: "#333333",
            dark: "#001064",
            contrastText: "#fff"
        },
        secondary: {
            light: "#5f5fc4",
            main: "#ff6f1f",
            dark: "#f60000",
            contrastText: "#fff"
        },
        error: red,
        contrastThreshold: 3,
        tonalOffset: 0.2
    },
    /* typography: {
        // Use the system font instead of the default Roboto font.
        fontFamily: ["-apple-system", "BlinkMacSystemFont", '"Segoe UI"', "Arial", "sans-serif"].join(",")
    },*/
    overrides: {
        MuiButton: {
            // override the styles of all instances of this component
            root: {
                // Name of the rule
                color: "white" // Some CSS
            }
        }
    }
});
