import React from "react";

const LogoColor = () => (
    <div
        dangerouslySetInnerHTML={{
            __html: `
            <svg version="1.1"  x="0px" y="0px"
            viewBox="0 0 610.46 356.25" style="width: 150px">
    <style type="text/css">
        .st0{fill:url(#SVGID_1_);}
        .st1{fill:url(#SVGID_2_);}
    </style>
    <g>
        
            <radialGradient id="SVGID_1_" cx="250.2335" cy="166.8961" r="230.9626" fx="426.7096" fy="17.8997" gradientUnits="userSpaceOnUse">
            <stop  offset="0" style="stop-color:#FF6F1F"/>
            <stop  offset="1" style="stop-color:#F60000"/>
        </radialGradient>
        <path class="st0" d="M180.01,290c-53.87,0-98.97-38.28-109.52-89.07h90.84v-45.59h-132c-1.12,7.44-1.71,15.05-1.71,22.8
            c0,7.75,0.59,15.36,1.71,22.79c11.04,73.25,74.4,129.59,150.68,129.59c41.49,0,79.11-16.7,106.62-43.69
            c-6.91-8.49-14.43-19.89-23.53-33.93C242.61,275.65,212.97,290,180.01,290z M325.91,116.62C282.52,54.89,241,25.93,183.1,25.74
            c-70.67-0.23-115.71,37.4-139.4,90.87h46.03c20.03-30.31,54.4-50.35,93.37-50.35c43.21,0,82.12,32.41,115.92,80.31
            c47.66,67.54,82.24,123.81,154.3,99.94C401.07,234.7,356.98,160.82,325.91,116.62z"/>
        
            <radialGradient id="SVGID_2_" cx="387.1161" cy="173.056" r="239.4198" fx="503.8006" fy="-4.2735" gradientUnits="userSpaceOnUse">
            <stop  offset="0" style="stop-color:#FF6F1F"/>
            <stop  offset="1" style="stop-color:#F60000"/>
        </radialGradient>
        <path class="st1" d="M430.45,25.73c-41.22,0-78.6,16.37-106.03,42.95c0.5,0.7,1,1.4,1.49,2.11c6.49,9.23,13.55,19.76,21.13,30.75
            c20.51-22.02,49.76-35.79,82.22-35.79c62.06,0,112.36,50.31,112.36,112.36s-50.31,111.77-112.36,111.77c-0.75,0-3.43,0.01-5.94,0
            c-41.51-2.04-78.99-33.87-111.68-80.2c-47.66-67.54-82.24-123.81-154.3-99.94c52.25,11.82,96.34,85.69,127.41,129.9
            c42.92,61.06,84.02,90.05,140.94,90.85v-0.09c1.58,0.05,3.16,0.09,4.74,0.09c84.16,0,152.39-68.23,152.39-152.39
            S514.61,25.73,430.45,25.73z"/>
    </g>
    </svg>
    `
        }}
    />
);

export default LogoColor;
