import React, { FC, ComponentType } from "react";
import TimelineOutlined from "@material-ui/icons/TimelineOutlined";
import Typography from "@material-ui/core/Typography";
import { useTranslate } from "react-admin";
import { SvgIconProps } from "@material-ui/core/SvgIcon";

import CardIcon from "./CardIcon";

interface Props {
    value?: string;
    title?: string;
    subtitle?: string;
    color?: string;
    icon?: ComponentType<SvgIconProps>;
}

function nl2br(str, is_xhtml) {
    if (typeof str === "undefined" || str === null) {
        return "";
    }
    var breakTag = is_xhtml || typeof is_xhtml === "undefined" ? "<br />" : "<br>";
    return (str + "").replace(/([^>\r\n]?)(\r\n|\n\r|\r|\n)/g, "$1" + breakTag + "$2");
}

const KpisCard: FC<Props> = ({ value, title, icon, color, subtitle }) => {
    const translate = useTranslate();
    return (
        <div className="cardmain">
            <CardIcon Icon={icon} bgColor="#000000" iconColor={color} />
            <div className="innercard">
                <h2 style={{ color }} className="MuiTypography-root kpinumber MuiTypography-body1" title={value} alt={value}>
                    {value}
                </h2>
                <Typography className="cardtitlekpi" dangerouslySetInnerHTML={{ __html: nl2br(translate(title), false) }}></Typography>
                {subtitle && <Typography className="cardsubtitle">{translate(subtitle)}</Typography>}
            </div>
        </div>
    );
};

export default KpisCard;
