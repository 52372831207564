import PeopleIcon from "@material-ui/icons/People";
import UserCreate from "./UserCreate";
import UserEdit from "./UserEdit";
import UserList from "./UserList";
import UserShow from "./UserShow";

export default {
    list: UserList,
    create: false,
    edit: UserEdit,
    show: false,
    icon: PeopleIcon,
};
