import React from "react";
import Highcharts from "highcharts";
import HighchartsReact from "highcharts-react-official";

const HighChart = (props) => {
    const options = {
        chart: {
            type: "spline",
        },
        title: {
            text: props.title,
        },
        xAxis: {
            title: {
                text: props.y,
            },
            categories: props.data.data.map((v) => v.key),
        },

        yAxis: {
            title: {
                text: props.x,
            },
            minorTickInterval: 1,
        },

        tooltip: {
            headerFormat: "<b>{series.name}</b><br />",
            pointFormat: "{point.y}",
        },
        plotOptions: {
            series: {
                color: props.color,
            },
        },

        series: [{ name: props.serie, data: props.data.data.map((v) => v.value * 1) }],
    };

    return (
        <div>
            <HighchartsReact highcharts={Highcharts} options={options} />
        </div>
    );
};

export default HighChart;
