import React from "react";
import ChevronLeft from "@material-ui/icons/ChevronLeft";
import ChevronRight from "@material-ui/icons/ChevronRight";
import PersonIcon from "@material-ui/icons/Person";
import { Avatar, Button, Card, CardActions, CardContent, CardHeader, Grid, Toolbar, useMediaQuery, makeStyles } from "@material-ui/core";
import jsonExport from "jsonexport/dist";
import { DateField, EditButton, Filter, List, PaginationLimit, ImageField, ReferenceInput, SearchInput, TextInput, ShowButton, SimpleList, TextField, downloadCSV, useTranslate } from "react-admin"; // eslint-disable-line import/no-unresolved
import config from "../config";

const CommentFilter = (props) => (
    <Filter {...props}>
        <SearchInput source="q" alwaysOn />
        <TextInput source="title_contains" defaultValue="" />
    </Filter>
);

const exporter = (posts) => {
    const data = posts.map((post) => ({
        ...post,
    }));
    jsonExport(data, (err, csv) => downloadCSV(csv, "banners"));
};

const CommentPagination = ({ loading, ids, page, perPage, total, setPage }) => {
    const translate = useTranslate();
    const nbPages = Math.ceil(total / perPage) || 1;
    if (!loading && (total === 0 || (ids && !ids.length))) {
        return <PaginationLimit total={total} page={page} ids={ids} />;
    }

    return (
        nbPages > 1 && (
            <Toolbar>
                {page > 1 && (
                    <Button color="primary" key="prev" onClick={() => setPage(page - 1)}>
                        <ChevronLeft />
                        &nbsp;
                        {translate("ra.navigation.prev")}
                    </Button>
                )}
                {page !== nbPages && (
                    <Button color="primary" key="next" onClick={() => setPage(page + 1)}>
                        {translate("ra.navigation.next")}&nbsp;
                        <ChevronRight />
                    </Button>
                )}
            </Toolbar>
        )
    );
};

const useListStyles = makeStyles((theme) => ({
    card: {
        height: "100%",
        display: "flex",
        flexDirection: "column",
    },
    cardContent: theme.typography.body1,
    cardLink: {
        ...theme.typography.body1,
        flexGrow: 1,
    },
    cardLinkLink: {
        display: "inline",
    },
    cardActions: {
        justifyContent: "flex-end",
    },
}));

const CommentGrid = ({ ids, data, basePath }) => {
    const translate = useTranslate();
    const classes = useListStyles();

    return (
        <Grid spacing={2} container>
            {ids.map((id) => (
                <Grid item key={id} sm={12} md={6} lg={4}>
                    <Card className={classes.card}>
                        <CardHeader className="comment" title={<TextField record={data[id]} source="title" />} subheader={<DateField record={data[id]} source="created_at" />} />
                        <CardContent className={classes.cardContent}>
                            <TextField record={data[id]} source="description" />
                            {data[id].image && data[id].image.length ? <img src={`${config.UPLOADS_BASE_URL}${data[id].image[0].url}`} style={{ width: 300, display: "block" }} /> : ""}
                        </CardContent>
                        <CardActions className={classes.cardActions}>
                            <EditButton resource="banners" basePath={basePath} record={data[id]} />
                            <ShowButton resource="banners" basePath={basePath} record={data[id]} />
                        </CardActions>
                    </Card>
                </Grid>
            ))}
        </Grid>
    );
};

CommentGrid.defaultProps = {
    data: {},
    ids: [],
};

const CommentMobileList = (props) => <SimpleList primaryText={(record) => record.title} secondaryText={(record) => record.description} tertiaryText={(record) => new Date(record.created_at).toLocaleDateString()} {...props} />;

const CommentList = (props) => {
    const isSmall = useMediaQuery((theme) => theme.breakpoints.down("sm"));

    return (
        <List {...props} perPage={6} exporter={exporter} filters={<CommentFilter />} pagination={<CommentPagination />} component="div">
            {isSmall ? <CommentMobileList /> : <CommentGrid />}
        </List>
    );
};

export default CommentList;
