import englishMessages from "ra-language-english";

export const messages = {
    simple: {
        action: {
            close: "Close",
            resetViews: "Reset views",
        },
        "create-post": "New post",
    },
    ...englishMessages,
    pos: {
        dashboard: {
            monthly_revenue: "Saldo Mensual",
            new_orders: "Transferencias Mensuales",
            pending_reviews: "Pendientes",
            new_customers: "Nuevos Clientes",
            connected_users: "Usuarios conectados",
            total_users: "Usuarios\ntotales",
            pending_orders: "Pending Orders",
            cash_flow: "Promedio Cashin",
            cash_flow2: "Promedio Cashout",
            transfer: "Transferencias",
            max_flow: "Cantidad CashIn",
            max_flow2: "Cantidad CashOut",
            net_balance: "Diferencia Cashin CashOut",
            order: {
                items: "by %{customer_name}, one item |||| by %{customer_name}, %{nb_items} items",
            },
            welcome: {
                title: "Welcome to react-admin demo",
                subtitle: "This is the admin of an imaginary poster shop. Feel free to explore and modify the data - it's local to your computer, and will reset each time you reload.",
                aor_button: "react-admin site",
                demo_button: "Source for this demo",
            },
        },
    },
    resources: {
        posts: {
            name: "Post |||| Posts",
            fields: {
                average_note: "Average note",
                body: "Body",
                comments: "Comments",
                commentable: "Commentable",
                commentable_short: "Com.",
                created_at: "Created at",
                notifications: "Notifications recipients",
                nb_view: "Nb views",
                password: "Password (if protected post)",
                pictures: "Related Pictures",
                published_at: "Published at",
                teaser: "Teaser",
                tags: "Tags",
                title: "Title",
                views: "Views",
                authors: "Authors",
            },
        },
        comments: {
            name: "Comment |||| Comments",
            fields: {
                body: "Body",
                created_at: "Created at",
                post_id: "Posts",
                author: {
                    name: "Author",
                },
            },
        },
        users: {
            name: "User |||| Users",
            fields: {
                name: "Name",
                role: "Role",
            },
        },
    },
    post: {
        list: {
            search: "Search",
        },
        form: {
            summary: "Summary",
            body: "Body",
            miscellaneous: "Miscellaneous",
            comments: "Comments",
        },
        edit: {
            title: 'Post "%{title}"',
        },
        action: {
            save_and_edit: "Save and Edit",
            save_and_add: "Save and Add",
            save_and_show: "Save and Show",
            save_with_average_note: "Save with Note",
        },
    },
    comment: {
        list: {
            about: "About",
        },
    },
    user: {
        list: {
            search: "Search",
        },
        form: {
            summary: "Summary",
            security: "Security",
        },
        edit: {
            title: 'User "%{title}"',
        },
        action: {
            save_and_add: "Save and Add",
            save_and_show: "Save and Show",
        },
    },
};

export default messages;
