import config from "../config";
import axios from "axios";
import Cookies from "../helpers/Cookies";

const API_URL = config.API_URL;
const HEADERS = {
    "Content-Type": "application/json",
    Accept: "application/json",
};

const GET = "get";
const POST = "post";

const instances = {
    noAuth: undefined,
    auth: undefined,
};

function Api() {
    this.config = {};
}

function init() {
    axios.defaults.baseURL = API_URL;
    Object.entries(HEADERS).map((header) => {
        const [key, value] = header;
        axios.defaults.headers.common[key] = value;

        return axios;
    });
}

function specialError(api, status) {
    console.log(status);
}

function errorsHandle(api, response = {}, vm) {
    return new Promise((resolve) => {
        if ((response.data || {}).message === "max-sessions") {
            return resolve(false);
        }
        const status = (response || {}).status || 0;
        if (status < 400) {
            return resolve(false);
        }
        if (status !== 401) {
            checkOptions(status, vm);
        }

        return resolve(specialError(api, status));
        // return resolve()
    });
}

function createAuth(api) {
    instances.auth = axios.create({
        headers: {
            common: {
                Authorization: `Bearer ${api.token}`,
            },
        },
    });
}

function createNoAuth() {
    instances.noAuth = axios.create();
}

function checkInstances(api) {
    if (!instances.noAuth) {
        createNoAuth();
    }

    if ((!instances.auth || (instances.auth && instances.auth.defaults.headers.common.Authorization !== `Bearer ${api.token}`)) && api.token) {
        createAuth(api);
    }
}

function get(url, options) {
    this.method = GET;
    this.url = url;
    this.body = undefined;
    return this.exec(options);
}

function post(url, body, options) {
    this.method = POST;
    this.url = url;
    this.body = body;
    return this.exec(options);
}

function exec(options = {}) {
    const vm = this;

    const token = Cookies.getCookie("token");
    if (token) {
        vm.token = token;
    }

    checkInstances(vm, options);

    let instance = instances.noAuth;

    if (vm.token) {
        instance = instances.auth;
    }

    return new Promise((resolve, reject) => {
        instance[vm.method](`${vm.url}`, vm.body, vm.config)
            .then((response) => {
                errorsHandle(vm, response, vm).then((res) => {
                    if (res) {
                        reject(response);
                    }

                    return resolve(response.data);
                });
            })
            .catch((error) => {
                errorsHandle(vm, error.response, vm);
                return reject(error.response);
            });
    });
}

Api.prototype = {
    get,
    post,
    exec,
};

init();

export default Api;
