/* eslint react/jsx-key: off */
import React from "react";
import PropTypes from "prop-types";
import { DeleteWithConfirmButton, Edit, BooleanInput, SimpleForm, required, SaveButton, SelectInput, ShowButton, TabbedForm, TextInput, Toolbar, TopToolbar } from "react-admin";
import { makeStyles } from "@material-ui/core/styles";

import UserTitle from "./UserTitle";

const useToolbarStyles = makeStyles({
    toolbar: {
        display: "flex",
        justifyContent: "space-between",
    },
});

/**
 * Custom Toolbar for the Edit form
 *
 * Save with undo, but delete with confirm
 */
const UserEditToolbar = (props) => {
    const classes = useToolbarStyles();
    return (
        <Toolbar {...props} classes={classes}>
            <SaveButton />
            <DeleteWithConfirmButton />
        </Toolbar>
    );
};

const UserEdit = ({ permissions, ...props }) => (
    <Edit title={<UserTitle />} {...props}>
        <SimpleForm defaultValue={{ role: "user" }} toolbar={<UserEditToolbar />}>
            <TextInput disabled source="id" />
            <TextInput source="name" defaultValue="slim shady" validate={required()} />
            <TextInput source="last_name" defaultValue="slim shady" validate={required()} />
            <TextInput source="username" defaultValue="slim shady" validate={required()} />
            <TextInput source="email" defaultValue="slim shady" validate={required()} />
            <TextInput source="dni" defaultValue="slim shady" validate={required()} />
            <BooleanInput source="confirmed" />
            <BooleanInput source="blocked" />
        </SimpleForm>
    </Edit>
);

UserEdit.propTypes = {
    id: PropTypes.any.isRequired,
    location: PropTypes.object.isRequired,
    match: PropTypes.object.isRequired,
    permissions: PropTypes.string,
};

export default UserEdit;
