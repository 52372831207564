import React from "react";
import { DateField, ImageField, Show, SimpleShowLayout, TextField } from "react-admin"; // eslint-disable-line import/no-unresolved

const CommentShow = (props) => (
    <Show {...props}>
        <SimpleShowLayout>
            <TextField source="id" />
            <TextField source="title" />
            <TextField source="order" />
            <DateField source="created_at" />
            <DateField source="updated_at" />
            <TextField source="description" />
            <ImageField source="image[0].url" label="Imagen" />
        </SimpleShowLayout>
    </Show>
);

export default CommentShow;
