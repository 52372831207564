/* eslint react/jsx-key: off */
import React from "react";
import "./index.css";
import { Admin, Resource, fetchUtils } from "react-admin"; // eslint-disable-line import/no-unresolved
import { render } from "react-dom";
import { Route } from "react-router-dom";
import LoginPage from "./LoginPage";

import simpleRestProvider from "ra-strapi-rest";
import config from "./config";
import authProvider from "./authProvider";
import comments from "./banners";
import CustomRouteLayout from "./customRouteLayout";
import CustomRouteNoLayout from "./customRouteNoLayout";
import dataProvider from "./dataProvider";
import i18nProvider from "./i18nProvider";
import Layout from "./Layout";
import posts from "./posts";
import users from "./users";
import tags from "./tags";
import { Dashboard } from "./dashboard";
import SwapHorizRoundedIcon from "@material-ui/icons/SwapHorizRounded";
import closeSidebarSaga from "./closeSidebarSaga";
import { bitYouTheme } from "./theme/bityou";
import Cookies from "./helpers/Cookies";
/*
const createFormData = (photo, body) => {
    const data = new FormData();
    //var blob = Blob.fromDataURL(photo.src);
    const reader = new FileReader();
    reader.readAsDataURL(photo.rawFile);

    // data.append("files.image", reader.result, photo.title);

    data.append("data", JSON.stringify(body));

    return data;
};*/

const uploadFields = ["image"];

const httpClient = (url, options = {}) => {
    if (!options.headers) {
        options.headers = new Headers({ Accept: "application/json" });
    }
    const token = Cookies.getCookie("token");
    options.headers.set("Authorization", `Bearer ${token}`);

    /* if (options.body && options.body.length) {
        var body = JSON.parse(options.body);
        if (body.image && body.image.src && body.image.src.length) {
            console.log(body.image);
            let photo = body.image;
            delete body.image;
            body.image = [];
            let formData = createFormData(photo, body);
            options.body = formData;
        }
    }*/

    return fetchUtils.fetchJson(url, options);
};

const dataStrapiProvider = simpleRestProvider(config.API_URL, httpClient, uploadFields);

const login = () => <LoginPage theme={bitYouTheme} />;

render(
    <Admin
        customSagas={[closeSidebarSaga]}
        authProvider={authProvider}
        dataProvider={dataStrapiProvider}
        i18nProvider={i18nProvider}
        dashboard={Dashboard}
        title="BitYou Admin"
        layout={Layout}
        loginPage={login}
        theme={bitYouTheme}
        customRoutes={[<Route exact path="/custom" component={(props) => <CustomRouteNoLayout {...props} />} noLayout />, <Route exact path="/custom2" component={(props) => <CustomRouteLayout {...props} />} />]}
    >
        {(permissions) => [<Resource name="users" {...users} />, <Resource name="notificaciones" {...posts} />, <Resource name="banners" {...comments} />]}
    </Admin>,
    document.getElementById("root")
);
